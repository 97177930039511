<template>
    <el-card shadow="never" class="box-card-b">
        <el-main v-loading="loading">
            <el-table :data="fileList" style="width: 100%" :height="innerHeight <= 400 ? 400 : innerHeight - 135">
                <el-table-column prop="name" label="链接名称" show-overflow-tooltip>
                    <template #header>
                        <el-button icon="Search" type="" text @click="namecak = false" v-if="namecak">链接名称<span
                                v-if="lookup.search.name">：{{lookup.search.name}}</span></el-button>
                        <el-input v-model="lookup.search.name" placeholder="搜索链接名称" v-else @change="namecakfu()"
                            @blur="namecak = true" clearable />
                    </template>
                </el-table-column>
                <el-table-column prop="remarks" label="链接描述" show-overflow-tooltip />
                <el-table-column prop="tag" label="链接标签" show-overflow-tooltip>
                    <template #header>
                        <el-button icon="Search" type="" text @click="tagcak = false" v-if="tagcak">标签<span
                                v-if="lookup.search.tag">：{{lookup.search.tag}}</span></el-button>
                        <el-input v-model="lookup.search.tag" placeholder="搜索标签" v-else @change="tagcakfu()"
                            @blur="tagcak = true" clearable />
                    </template>
                </el-table-column>
                <el-table-column prop="url" label="点击查看" show-overflow-tooltip>
                    <template #default="scope">
                        <el-link icon="Paperclip" type="primary" :href="scope.row.url"
                            target="_blank">{{scope.row.url}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column label="操作" v-if="counterpoise_add">
                    <template #header>
                        <el-button icon="CirclePlusFilled" text type="warning" @click="addv">操作/添加</el-button>
                    </template>
                    <template #default="scope">
                        <el-button text type="primary" size="small" @click="tabClick(scope.row)">编辑</el-button>
                        <el-button text type="danger" size="small" disabled>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <el-footer height='40'>
            <el-pagination v-model:current-page="lookup.currentPage" background v-model:page-size="lookup.pageSize"
                hide-on-single-page layout="prev, pager, next, jumper " :total="lookup.total"
                @size-change="handleCurrentChange" @current-change="handleCurrentChange" />
        </el-footer>
    </el-card>
    <CatChange :changedits='edit' @updatas='updatas' @deletes='deletes' @cancels="edit = false" :changeForms='form'
        :changeHeads='tableHead' />
    <CatAdd :changedits='addedit' @updatas='updatas' @deletes='deletes' @cancels="addedit = false" :changeForms='form'
        :changeHeads='tableHead' />
</template>

<script>
    // 知识库页面
    import CatAdd from '@/components/CatAdd.vue'
    import CatChange from '@/components/CatChange.vue'
    import axios from "@/utils/request";
    import {
        ElMessage,
        ElLoading,
    } from 'element-plus'
    import {
        mapState
    } from 'vuex'
    export default {
        name: 'Repository',
        components: {
            CatAdd,
            CatChange,
        },
        computed: {
            // Vue计算属性
            ...mapState(['innerHeight']),
        },
        created() {
            this.handleCurrentChange()
        },
        data() {
            return {
                namecak: true,
                tagcak: true,
                lookup: {
                    tabname: '知识库表',
                    modifyData: false, //修改指令
                    search: {},
                    export: false,
                    currentPage: 1, //当前所在分页页数
                    pageSize: 20, //一页显示多少数据
                    total: 20, //总共有多少数据
                    data: {}
                },
                tableHead: [],
                loading: false,
                fileList: [],
                edit: false,
                addedit: false,
                form: {},
                counterpoise_add: 0,
                counterpoise_edit: 0,
            }
        },
        methods: {
            handleCurrentChange() {
                this.loading = true
                axios({
                    method: "post",
                    url: 'getdata',
                    data: this.lookup,
                }).then(res => {
                    this.fileList = res.data //数据本身
                    this.lookup.total = res.total
                    this.counterpoise_add = res.tableHead.add //添加权限
                    this.tableHead = res.tableHead.tableHead //数据表头
                    this.loading = false
                })
            },
            namecakfu() {
                this.namecak = true
                this.handleCurrentChange()
            },
            tagcakfu() {
                this.tagcak = true
                this.handleCurrentChange()
            },
            deletes(v) {
                this.form = v
                this.edit = false
                this.addedit = false
                if (this.form.index != undefined) {
                    this.fileList.splice(this.form.index, 1)
                }
            },
            addv() { //增加
                this.addedit = false
                this.form = {}
                this.addedit = true
            },
            tabClick(row) {
                //筛选数据
                this.edit = true
                this.form = row
            },
            updatas() {
                this.edit = false
                this.lookup.data[0] = this.form
                this.loading = true
                this.addedit = false
                axios({
                    method: "post",
                    url: 'updata',
                    data: this.lookup,
                }).then(res => {
                    ElMessage({
                        type: "success",
                        message: `添加${res.add}行数据，更新${res.update}行数据`,
                    })
                    if (res.addate) {
                        res.addate.map((vs) => {
                            this.fileList.push(vs)
                        })
                    }
                    this.loading = false
                })

            }

        }
    }
</script>

<style scoped>
</style>